export const ignoreList = [
	"NotFoundError",
	"Hydration Error",
	"TypeError",
	"RangeError",
	"SecurityError",
	"InvalidCharacterError",
	// "Connection closed", // EZProxy error — we want these for now
	"Maximum call stack size exceeded",
	"Uncaught RangeError",
	"Network Error",
];
