globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"2ad76ed7ad5714f9138a98016595de2be69c2c08"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs";

import { ignoreList } from "@/lib/data/ignore-errors";

Sentry.init({
	dsn: "https://201b366417094deb8783821735a6b507@o18393.ingest.us.sentry.io/6662606",
	// Replay may only be enabled for the client-side
	integrations: [Sentry.replayIntegration()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	// We recommend adjusting this value in production
	tracesSampleRate: 0.001,

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: 1.0,

	// ...

	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps

	enabled: process.env.NODE_ENV === "production",
	allowUrls: [/https?:\/\/((cdn|www)\.)?examine\.com/, /examine-com\.\w+\.idm\.oclc\.org/],
	ignoreErrors: [...ignoreList, /^Exact Match Message$/],
});
